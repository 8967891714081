<template>
  <div>
    <div class="uk-form-horizontal filter-container">
      <div
        class="uk-child-width-expand@s uk-grid-large"
        uk-grid
      >
        <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-first-column">
          <label
            class="uk-form-label"
            for="form-horizontal-text"
          >Tanggal Update</label>
          <div class="uk-form-controls">
            <div
              uk-grid
              class="uk-grid-small"
            >
              <div class="uk-width-2-5@s">
                <datepicker
                  ref="createdStart"
                  v-model="metaFilter.created_start"
                  input-class="uk-input"
                  placeholder="DD-MM-YYYY"
                />
              </div>
              <div class="uk-width-1-5@s uk-text-center filter-padding">
                to
              </div>
              <div class="uk-width-2-5@s">
                <datepicker
                  ref="createdEnd"
                  v-model="metaFilter.created_end"
                  input-class="uk-input"
                  placeholder="DD-MM-YYYY"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="uk-width-1-1@s uk-width-2-3@m uk-width-1-2@l">
          <label
            class="uk-form-label"
            for="cpt"
          > Tipe Harga Kontrak </label>
          <div class="uk-form-controls">
            <multiselect
              v-model="priceTypeChoose"
              v-validate="'required'"
              name="cpt"
              label="name"
              track-by="value"
              :placeholder="contractPriceTypeList[0].name"
              :options="contractPriceTypeList"
              open-direction="bottom"
              :loading="isLoading"
              :close-on-select="true"
              :max-height="500"
              :show-no-result="true"
            />
          </div>
        </div>
      </div>
      <div
        class="uk-child-width-expand@s uk-grid-small uk-grid"
        uk-grid
      >
        <div class="uk-width-1-1 uk-text-right">
          <button
            class="uk-button uk-button-default uk-width-expand uk-width-auto@s"
            @click="clearFilter()"
          >
            <span
              uk-icon="icon: close; ratio: 0.9"
              class="uk-margin-small-right"
            />Clear
          </button>
          <button
            class="uk-button uk-button-primary uk-margin-top uk-margin-remove-top-med uk-margin-remove-left@s uk-margin-left-med uk-width-expand uk-width-auto@s"
            @click="handleFind()"
          >
            <span
              uk-icon="icon: search; ratio: 0.9"
              class="uk-margin-small-right"
            />Cari
          </button>
        </div>
      </div>
    </div>
    <div
      class="uk-child-width-expand@s uk-grid-small uk-margin-top"
      uk-grid
    >
      <div class="uk-width-1-1 uk-width-1-2@s uk-width-1-2@m uk-width-1-4@l">
        <button
          v-if="isCanAccessUser(`add`, `Harga Kontrak`)"
          class="uk-button uk-button-default uk-width-expand uk-width-auto@s"
          @click="toUpdate()"
        >
          <img
            v-lazy="`${images}/icon/edit-black.svg`"
            style="margin-right: 8px"
            alt=""
            class="img-click"
          >Update Harga
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex"
import Datepicker from "vuejs-datepicker"
import { dateString } from "@/utils/formater"
import { isCanAccess } from "@/utils/auth"
import { PREFIX_IMAGE, CONTRACT_PRICE_TYPE_LIST } from "@/utils/constant"

export default {
  components: {
    Datepicker
  },
  data() {
    return {
      images: PREFIX_IMAGE,
      metaFilter: {
        created_start: "",
        created_end: "",
        price_type: ''
      },
      contractPriceTypeList: CONTRACT_PRICE_TYPE_LIST,
      priceTypeChoose: ''
    }
  },
  computed: {
    ...mapGetters({
      contractPrice: "contractPrice/contractPrice",
      meta: "contractPrice/meta"
    })
  },
  watch: {
    "metaFilter.created_start"() {
      this.metaFilter.created_start = this.dateFormatFilter(this.metaFilter.created_start)
    },
    "metaFilter.created_end"() {
      this.metaFilter.created_end = this.dateFormatFilter(this.metaFilter.created_end)
    },
    priceTypeChoose() {
      this.metaFilter.price_type = this.priceTypeChoose.value
      this.setPriceType(this.metaFilter.price_type)
    }
  },
  async mounted() {
    this.priceTypeChoose = this.contractPriceTypeList[0]
    this.setPriceType(this.priceTypeChoose.value)
  },
  methods: {
    dateFormatFilter(date) {
      return dateString(date)
    },
    isCanAccessUser(type, menu) {
      return isCanAccess(type, menu)
    },
    ...mapActions({
      getContractPrice: "contractPrice/getContractPrice"
    }),
    ...mapMutations({
      setMeta: "contractPrice/SET_META",
      setPriceType: "contractPrice/SET_PRICE_TYPE"
    }),
    handleFind() {
      this.metaFilter.page = 1
      this.setMeta({
        ...this.meta,
        ...this.metaFilter
      })
    },
    toUpdate() {
      this.$router.push(`/admin/harga-kontrak/edit/${this.contractPrice.data[0].id}`)
    },
    async clearFilter() {
      const app = this
      app.metaFilter.created_start = ""
      app.metaFilter.created_end = ""
      app.priceTypeChoose = app.contractPriceTypeList[0]
      app.metaFilter.price_type = app.priceTypeChoose.value
      await app.handleFind()
    }
  }
}
</script>
