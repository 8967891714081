<template>
  <div class="uk-margin-top">
    <div class="uk-overflow-auto">
      <table class="uk-table uk-table-small uk-table-divider">
        <thead class="thead-table-paranje">
          <tr>
            <th class="column-title uk-width-medium">
              <span class="uk-text-bold">Berlaku Sejak</span>
            </th>
            <th class="column-title uk-width-small">
              <span class="uk-text-bold">Kode Harga</span>
            </th>
            <th class="column-title uk-width-medium">
              <span class="uk-text-bold">Tipe Harga</span>
            </th>
            <th class="column-title uk-width-medium">
              <span class="uk-text-bold">Harga DOC/ekor</span>
            </th>
            <th class="column-title uk-width-medium">
              <span class="uk-text-bold">Harga Pakan/kg</span>
            </th>
            <th class="column-title uk-width-medium">
              <span class="uk-text-bold">Harga OVK/pcs</span>
            </th>
            <th class="column-title uk-width-medium">
              <span class="uk-text-bold">Harga Jual Ayam/Kg</span>
            </th>
            <th class="column-title uk-width-medium">
              <span class="uk-text-bold">Terakhir Update</span>
            </th>
            <th class="column-title uk-table-expand uk-text-center">
              <span class="uk-text-bold">Aksi</span>
            </th>
          </tr>
        </thead>
        <template v-if="!is_loading">
          <tbody v-if="contractPrice.meta.total_count>0">
            <tr
              v-for="(doc, i) in contractPrice.data"
              :key="i"
            >
              <td>{{ doc.effective_date || '-' }}</td>
              <td>{{ doc.code || '-' }}</td>
              <td>{{ doc.price_type || '-' }}</td>
              <td>{{ priceFormat(doc.doc_price) || '-' }}</td>
              <td>{{ priceFormat(doc.feed_price) || '-' }}</td>
              <td>{{ priceFormat(doc.ovk_price) || '-' }}</td>
              <td>{{ priceFormat(doc.contract_price_chicken[0].price) +' - '+ priceFormat(doc.contract_price_chicken[doc.contract_price_chicken.length - 1].price) || '-' }}</td>
              <td>{{ doc.updated_at || '-' }}</td>
              <td class="uk-flex uk-flex-center">
                <img
                  v-if="isCanAccessUser(`edit`, `Harga Kontrak`)"
                  v-lazy="`${images}/icon/eye.svg`"
                  alt=""
                  class="uk-margin-small-left img-click"
                  @click="showDetail(doc.id)"
                >
              </td>
            </tr>
          </tbody>
          <empty-table
            v-else
            :colspan="10"
          />
        </template>
        <template v-else>
          <loading-table :colspan="10" />
        </template>
      </table>
    </div>
    <pagination
      :total-data="contractPrice.meta.total_count"
      :change-limit="changeLimit"
      :change-page="changePage"
      :current-page="meta.page"
      :current-limit="meta.limit"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Pagination from '@/components/globals/Pagination'
import EmptyTable from '@/components/globals/table/EmptyTable'
import LoadingTable from '@/components/globals/table/LoadingTable'
import { PREFIX_IMAGE } from '@/utils/constant'
import { isCanAccess } from '@/utils/auth'
import { currency } from '@/utils/formater'

export default {
  components: {
    EmptyTable,
    LoadingTable,
    Pagination
  },
  data() {
    return {
      is_loading: true,
      images: PREFIX_IMAGE
    }
  },
  computed : {
    ...mapGetters({
      contractPrice: 'contractPrice/contractPrice',
      meta: 'contractPrice/meta',
      priceType: 'contractPrice/priceType'
    })
  },
  watch: {
    async meta(a,b) {
      // a = after; b = before; line for skipping redudancy process
      if(a!=b){
        this.is_loading = true
        await this.getContractPrice({...this.meta, price_type: this.priceType})
        this.is_loading = false
      }
    }
  },
  async mounted() {
    await this.getContractPrice({...this.meta, price_type: this.priceType})
    this.is_loading = false
  },
  methods: {
    priceFormat(v){
      return currency(v)
    },
    isCanAccessUser(type, menu) {
      return isCanAccess(type, menu)
    },
    ...mapActions({
      getContractPrice: 'contractPrice/getContractPrice'
    }),
    ...mapMutations({
      setMeta: 'contractPrice/SET_META',
      setModalDelete: 'contractPrice/SET_MODAL_DELETE'
    }),
    changeLimit(e) {
      this.setMeta({
        ...this.meta,
        page: 1,
        limit: e.target.value
      })
    },
    changePage(value) {
      this.setMeta({
        ...this.meta,
        page: value,
        price_type: this.priceType
      })
    },
    showDetail(id) {
      this.$router.push(`/admin/harga-kontrak/detail/${id}`)
    }
  }
}
</script>

<style scoped>
.column-title {
  vertical-align: inherit;
}
</style>
