<template>
  <MainCard :data-header="dataHeader">
    <container />
    <div id="container-modal-delete" />
  </MainCard>
</template>

<script>
import getHeaderTitle from '@/utils/header-title'
import Container from './container'
import MainCard from '@/components/globals/card'
import { dateString } from '@/utils/formater'

export default {
  components: {
    MainCard,
    Container
  },
  data() {
    return {
      dataHeader: {
        title: '',
        isBreadcrumb: false,
        breadcrumb: ''
      }
    }
  },
  mounted() {
    this.dataHeader = {
      ...this.dataHeader,
      title: getHeaderTitle(this.$route.fullPath)
    }
  },
  methods: {
    getDatetime(date) {
      return dateString(date)
    }
  }
}
</script>
